
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
        *{
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            font-family: 'Poppins', sans-serif
        }
    @media screen and (min-width:600px) {  
        .form_container{
            background: linear-gradient(135deg,#71b7e6,#9b59b6);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .add_product_container{
            max-width: 700px;
            width: 100%;
            background: #fff;
            padding: 25px 30px;
            border-radius: 5px;
            justify-content: center;

        }
        .add_product_title{
            font-size: 25px;
            font-weight: 500;
            position: relative;
        }
        .add_product_title::before{
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: 3px;
            width: 30px;
            border-radius: 5px;
            background: linear-gradient(135deg, #71b7e6, #9b59b6);
        }
        .add_product_container form .product_detail, .add_product_container form .product_variant_detail {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 20px 0
        }
        .add_product_container form .product_detail .input-box,
        .add_product_container form .product_variant_detail .input-box{
            margin-bottom: 15px;
            width: calc(100% / 2 - 20px)
        }
        .add_product_container form .product_detail .input-box .details,
        .add_product_container form .product_variant_detail .input-box .details{
            display: block;
            font-weight: 500;
            margin-bottom: 5px
        }
        .add_product_container form .product_detail .input-box input,
        .add_product_container form .product_variant_detail .input-box input{
            height: 45px;
            width: 100%;
            outline: none;
            border-radius: 5px;
            border: 1px solid #ccc;
            padding-left: 15px;
            font-size: 16px;
            border-bottom-width: 2px;
            transition: all 0.3s ease
        }
        /* .product_details{
            display: flex;
            background-color: aqua;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 20px 0
        } */
        form .button{
            height: 45px;
            margin: 45px 0;
            justify-content: space-between;
            align-items: center;
            display: flex;
            flex-direction: column;
        }
        form .button input{
            height: 100%;
            width: 100%;
            outline: none;
            color: #fff;
            border: none;
            font-size: 18px;
            font-weight: 500;
            border-radius: 5px;
            letter-spacing: 1px;
            background: linear-gradient(135deg, #71b7e6, #9b59b6);
        }
        form .button input:hover{
            background: linear-gradient(135deg, #353f46, #9b59b6);
            cursor: pointer;
        }
        .product_details,
        .variant_details{
            display: flex;
            flex-direction: column;
        }
        .details_inputs, 
        .variant_details_inputs{

            display: flex;
            flex-direction: row;
        }
        form .button .btn_more_description{
            background: linear-gradient(135deg,  #9b59b6 ,#71b7e6);
            color: #fff;
            font-size: 18px;
            font-weight: 500;
            border-radius: 5px;
            letter-spacing: 1px;
            cursor: pointer;
            padding: 2px;
            margin-bottom: 15px;
            justify-self: center;
            align-self: center;
            display: flex;
           
        }
        .add_file_button{
            background: linear-gradient(135deg,  #9b59b6 ,#71b7e6);
            color: #fff;
            font-size: 18px;
            font-weight: 500;
            border-radius: 5px;
            letter-spacing: 1px;
            cursor: pointer;
            padding: 2px;
            margin-bottom: 15px;
            justify-self: center;
            align-self: center;
            display: flex;
           
        }
        .file_inputs input{
         
            background: #e6b371;
            border-radius: 20px;
            padding: 8px;
        }
        /* .detail_description{
            background-color: #8a0707;
            width: fit-content;
            
            max-width: 250px;
        } */
        .details_inputs input, .variant_details input{
            border-radius: 5px;
            font-size: 15px;
            width: auto;
            padding: 5px;
        }
        .variant_details_title,
        .details_title{
            font-size: 20px;
            font-weight: bold;
        }
    }
@media screen and (max-width: 600px) {
    .form_container{
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    .add_product_container{
        max-width: fit-content;
    }
    .product_detail{
        /* align-items: center; */
        display:flex ;
        flex-direction: column;
        width: 100%;

    }
    .product_detail .input-box{
        margin-left: 0px;
    }

    form .button {
        height: auto;
        margin: 45px 0;
        justify-content: space-between;
        align-items: center;
        display: flex;
        flex-direction: column;
        
    }

    form .button input {
        height:auto;
        width: 60%;
        outline: none;
        color: #fff;
        border: none;
        font-size: 18px;
        font-weight: 500;
        border-radius: 5px;
        letter-spacing: 1px;
        background: linear-gradient(135deg, #71b7e6, #9b59b6);
    }

    form .button input:hover {
        background: linear-gradient(135deg, #353f46, #9b59b6);
        cursor: pointer;
    }
    .details_inputs input,
    .variant_details input {
        border-radius: 5px;
        font-size: 15px;
        width: auto;
        padding: 5px;
    }

    .variant_details_title,
    .details_title {
        font-size: 20px;
        font-weight: bold;
    }
    .product_detail span{
        background-color: cyan;
        position: relative;
        align-self: center;
        border: solid 1px green;
        padding-left: 0;
        margin-left: 0px;

    
    }
   
}
