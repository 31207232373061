*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  font-family: 'Poppins', sans-serif;
}
.navbar{
  display: flex;  
  align-items: center;
  padding: 20px;
  height: 70px;
}
.navbar .logo img {
  display: block;
  height: 100%;
  width: 250px;
}
nav{
  flex: 1;
  text-align: right;
}
nav ul{
  display: inline-block;
  list-style-type: none;
}
nav ul li{
  display: inline-block;
  margin-right: 20px;
}
nav ul li a:hover{
  color: #ff523b;
}
.navbar i:hover{
  color: #ff523b;
}
a{
  text-decoration: none;
  color: #555;
}
p{
  color: #555;
}
.container{
  max-width: 1300px;
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
}
.row{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}
.col-2{
  flex-basis: 50%;
  min-width: 300px;
}
.col-2 img{
  max-width: 100%;
  padding: 50px 0;
}
.col-2 h1{
  font-size: 50px;
  line-height: 60px;
  margin: 25px 0;
}
.btn{
  display: inline-block;
  background: #ff523b;
  color: #fff;
  padding: 8px 30px;
  margin: 30px 0;
  border-radius: 30px;
  transition: background 0.5s;
}
.btn:hover{
  background: #563434;
}
.header{
  background: radial-gradient(#fff, #ffd6d6); 
}
.header .row{
  margin-top: 70px;
}
.categories{
  margin: 70px 0;
}
.col-3{
  flex-basis: 30%;
  min-width: 250px;
  margin-bottom: 30px;
}
.col-3 img{
  width: 100%;
}
.small-container{
  max-width: 1080px;
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
}
.col-4{
  flex-basis: 25%;
  padding: 10px;
  min-width: 200px;
  margin-bottom: 50px;
  transition: transform 0.5s;
}
.col-4 img{
  width: 100%;
}
.title{
  text-align: center;
  margin: 0 auto 80px;
  position: relative;
  line-height: 60px;
  color: #555;
}
.title::after{
  content: '';
  background: #ff523b;
  width: 80px;
  height: 5px;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.title:hover{
  color: #ff523b;
}
.title:hover::after{
  background: #563434;
}
h4{
  color: #555;
  font-weight: normal;
}
.col-4 p{
  font-size: 14px;
}
.col-4 img{
  background: rgb(240, 238, 238);
}
.rating .bx{
  color: #ff523b;
}
.quantity-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.quantity-container button {
  width: 30px;
  height: 30px;
  font-size: 18px;
  cursor: pointer;
}

.quantity-container input {
  width: 50px;
  text-align: center;
  margin: 0 10px;
  font-size: 16px;
}
.col-4:hover{
  transform: translateY(-5px);
}
.offer{
  background: radial-gradient(#fff, #ffd6d6);
  margin-top: 80px;
  padding: 30px 0;
}
.col-2 .offer-img{
  padding: 50px;
}
small{
  color: #555;
}
.testimonial{
  padding-top: 100px;
}
.testimonial .col-3{
  text-align: center;
  padding: 40px 20px;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.5s;
}
.testimonial .col-3 img{
  width: 50px;
  margin-top: 20px;
  border-radius: 50%;
}
.testimonial .col-3:hover{
  transform: translateY(-10px);
}
.fa.fa-quote-left{
  font-size: 34px;
  color: #ff523b;
}
.col-3 p{
  font-size: 12px;
  margin: 12px 0;
  color: #777;
}
.testimonial .col-3 h3{
  font-weight: 600;
  color: #555;
  font-size: 16px;
}
.brands{
  margin: 100px auto;
}
.brands .col-5{ 
  width: 160px;
}
.brands .col-5 i{
  width: 100%;
  cursor: pointer;
  filter: grayscale(100%);
 
}
.brands .col-5 i:hover{
  filter: grayscale(0);
  color: blue;
}

.brands .col-5 i.bx{
      font-size: 4rem;
  }

.footer{
  background: #000;
  color: #8a8a8a;
  font-size: 14px;
  padding: 30px 0 20px;   
}
.footer p{  
  color: #8a8a8a;
}
.footer h3{
  color: #fff;
  margin-bottom: 20px;
}
.footer-col-1, .footer-col-2, .footer-col-3, .footer-col-4{
  min-width: 250px;
  margin-bottom: 20px;
}
.footer-col-1{
  flex-basis: 30%;
}
.footer-col-2{
  flex: 1;
  text-align: center;
}
.footer-col-2 img{
  width: 180px;
  margin-bottom: 20px;
}
.footer-col-3, .footer-col-4{
  flex-basis: 12%;
  text-align: center;
}
ul{
  list-style-type: none;
}
.app-logo{
  margin-top: 20px;
}
.app-logo img{  
  width: 140px;
}
.footer hr{
  border: none;
  background: #b5b5b5;
  height: 1px;
  margin: 10px 0;
}
.copyright{
  text-align: center;
}
.bx.bx-menu.menu-icon {
  font-size: 28px;
  margin-left: 20px;
  display: none;
}
.edit_product_details{
  display: none;
}

@media only screen and (max-width: 800px){

  nav ul{
      position: absolute;
      top: 90px;
      left: 0;
      background: #333;
      width: 100%;
      overflow: hidden;
      transition: max-height 0.5s;
  }
  nav ul li{
      display: block;
      margin-right: 50px;
      margin-top: 10px;
      margin-bottom: 10px;
  }
  nav ul li a{
      color: #fff;
  }
  .bx.bx-menu.menu-icon{
      display: block;
      cursor: pointer;
  }
      .brands .col-5 i.bx {
          font-size: 2rem;
      }
  
}


.row-2{
  justify-content: space-between;
  margin: 100px auto 50px;
}
select{
  border: 1px solid #ff523b;
  padding: 5px;
}
select:focus{
  outline: none;
}
.page-btn{
  margin: 0 auto 80px;
}
.page-btn span{
  display: inline-block;
  border: 1px solid #ff523b;
  margin-left: 10px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.page-btn span:hover{
  background: #ff523b;
  color: #fff;
}

.single-product{
  margin-top: 80px;
}
.single-product .col-2 img{
  padding: 0;
}
.single-product .col-2{
  padding: 20px;
}
.single-product h4{
  margin: 20px 0;
  font-size: 22px;
  font-weight: bold;
}
.single-product select{
  display: block;
  padding: 10px;
  margin-top: 20px;
}
.single-product input{
  width: 50px;
  height: 40px;
  padding-left: 10px;
  font-size: 20px;
  margin-right: 10px;
  border: 1px solid #ff523b;
}
input:focus{
  outline: none;
}
.single-product .bx{
  color: #ff523b;
  margin-left: 10px;
}
.small-img-row{
  display: flex;
  justify-content: space-between;
}
.small-img-col{
  flex-basis: 24%;
  cursor: pointer;
}

.cart-page{
  margin: 80px auto;
}
table{
  width: 100%;
  border-collapse: collapse;

}
.cart-info{ 
  display: flex;
  flex-wrap: wrap;
}
th{
  text-align: left;
  padding: 5px;
  color: #fff;
  background: #ff523b;
  font-weight: normal;
}
td{
  padding: 10px 5px;
}
td input{
  width: 40px;
  height: 30px;
  padding: 5px;
}
td a{ 
  color: #ff523b;
  font-size: 12px;
}
td img{
  width: 80px;
  height: 80px;
  margin-right: 10px;
}
.total-price{
  display: flex;
  justify-content: flex-end;
}
.total-price table{
  border-top: 3px solid #ff523b;
  width: 100%;
  max-width: 400px;
}
td:last-child{
  text-align: right;
}
th:last-child{
  text-align: right;
}




.account-page {
  padding: 50px 0;
  background: radial-gradient(#fff, #ffd6d6);
}

.form-container {
  background: #fff;
  width: 300px;
  height: 500px;
  position: relative;
  text-align: center;
  padding: 20px 0;
  margin: auto;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.form-container span {
  font-weight: bold;
  padding: 0 10px;
  color: #555;
  cursor: pointer;
  width: 100px;
  display: inline-block;
}

.form-btn {
  display: inline-block;
  position: relative;
}

.form-container form {
  max-width: 300px;
  padding: 0 20px;
  position: absolute;
  top: 0px;
  width: 100%;
  transition: left 0.5s ease;
  /* Added transition for sliding */
}

form input {
  width: 100%;
  height: 30px;
  margin: 10px 0;
  padding: 0 10px;
  border: 1px solid #ccc;
}

form .btn {
  width: 100%;
  border: none;
  cursor: pointer;
  margin: 10px 0;
  background: #ff523b;
  color: #fff;
  padding: 10px;
}

form .btn:focus {
  outline: none;
}

#Indicator {
  width: 100px;
  border: none;
  background: #ff523b;
  height: 3px;
  margin-top: 8px;
  transform: translateX(0);
  transition: transform 0.5s ease;
  /* Smooth transition for hr */
}






.footer {
  background: #1c1c1c;
  /* Dark background */
  color: #f5f5f5;
  /* Light text color */
  padding: 30px 0 20px;
}

.footer h3 {
  color: #ff523b;
  /* Accent color for headings */
  margin-bottom: 20px;
  font-size: 20px;
  /* Increased font size */
}

.footer p {
  margin: 0 0 10px;
  /* Reduced margin for paragraphs */
}

.footer-col {
  flex: 1;
  /* Each column takes equal space */
  min-width: 250px;
  /* Minimum width for responsiveness */
  margin-bottom: 20px;
  padding: 0 20px;
  /* Padding for columns */
}

.footer-col img {
  width: 180px;
  margin-bottom: 20px;
}

.footer-col ul {
  list-style-type: none;
  padding: 0;
  /* No default padding */
}

.footer-col ul li {
  margin: 5px 0;
  /* Margin for list items */
}

.footer-col ul li a {
  color: #f5f5f5;
  /* Link color */
  transition: color 0.3s;
  /* Transition for hover effect */
}

.footer-col ul li a:hover {
  color: #ff523b;
  /* Hover effect */
}

.app-logo img {
  width: 140px;
  margin-right: 10px;
  /* Margin between app logos */
}

.footer hr {
  border: none;
  background: #444;
  /* Lighter divider */
  height: 1px;
  margin: 10px 0;
}

.copyright {
  text-align: center;
  font-size: 14px;
  /* Font size for copyright */
}

/* code to push this to the right of other text  */
.categories-ribbon, .subcategories-ribbon{
 padding-left: 20%;
justify-content: space-between;

}
.categories-container, .subcategories-container{
  padding: 10px;
}
.categories-container .btn, .subcategories-container .btn{
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 5px;

}
.categories-container tr, .subcategories-container tr{
  margin-bottom: 0px; 
}
.categories-container input, .subcategories-container input{
  /* border: solid 1px #ff523b; */
  justify-content: space-between;
  width: auto;


}
.categories-container .edit-form, .subcategories-container .edit-form{
  margin-left: 10px;
  width: 100%;
  /* border:solid 1px #ff523b; */
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.save-cancel-btn button{
  background-color: #563434;
}
.save-cancel-btn button:hover{
  background-color: #ff523b;
}


@media only screen and (max-width: 600px) {
  .row {
    text-align: center;
  }

  .col-2,
  .col-3,
  .col-4 {
    flex-basis: 100%;
  }

  brands .col-5 i.bx {
    font-size: 1rem;
    background: rebeccapurple;
  }

  .single-product .row {
    text-align: left;
  }

  .single-product .col-2 {
    padding: 20px 0;
  }

  .single-product h1 {
    font-size: 26px;
    line-height: 32px;
  }

  .cart-info p {
    display: none;
  }
  .footer {
    padding: 20px 0;
    /* Reduced padding */
  }

  .row {
    flex-direction: column;
    /* Stack columns */
    text-align: center;
    /* Center text */
  }

  .footer-col {
    margin-bottom: 20px;
  }

  .app-logo {
    display: flex;
    justify-content: center;
    /* Center app logos */
  }
  .edit_form_container form .details-table {
    display: none;
  }

  .edit_product_details{
    display: flex;
    flex-direction: column;
  }
}


 .edit_form_container {
   max-width: 800px;
   margin: 20px auto;
   padding: 20px;
   background: #fff;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   border-radius: 8px;
 }

 h1 {
   text-align: center;
   color: #444;
 }

 .product-image {
   display: flex;
   gap: 10px;
   flex-wrap: wrap;
 }

 .product-image img {
   width: 150px;
   height: auto;
   border: 1px solid #ccc;
   border-radius: 4px;
 }

 form {
   margin-top: 20px;
 }

 form label {
   font-weight: bold;
   display: block;
   margin-top: 10px;
 }

 form input,
 form textarea,
 form select {
   width: 100%;
   padding: 8px;
   margin-top: 5px;
   border: 1px solid #ccc;
   border-radius: 4px;
 }

 form button {
   background-color: #007BFF;
   color: white;
   border: none;
   padding: 10px 20px;
   border-radius: 5px;
   cursor: pointer;
   margin-top: 20px;
 }

 form button:hover {
   background-color: #0056b3;
 }

 .details-table {
   width: 100%;
   border-collapse: collapse;
   margin-top: 20px;
 }

 .details-table th,
 .details-table td {
   border: 1px solid #ddd;
   padding: 8px;
 }

 .details-table th {
   background-color: #7c7a7a;
 }

 .image-controls {
   display: flex;
   flex-direction: column;
   gap: 5px;
 }

 .image-controls button {
   padding: 5px;
   font-size: 12px;
 }



 @media only screen and (max-width: 370px) {
  html{
    width: 100%;
  }
  body{
    /* border: 3px solid greenyellow; */
    width:fit-content;
  }
.app-logo{
    display: flex;
    flex-direction: column;
    align-items: center;
   
  }

  
 }